import React, { useState, useEffect, useContext } from 'react';
import { CpButton, CpModal, CpOverlay } from 'canopy-styleguide!sofe';
import { useWithUserAndTenant } from 'cp-client-auth!sofe';
import { featureEnabled } from 'feature-toggles!sofe';

import { handleError } from 'src/common/handle-error.helper';
import { ApplyCreditModal } from './apply-credit-modal.component';
import { RefundCreditModal } from './refund-credit-modal.component';
import { CreditOverlayActions } from './credit-overlay-actions.component';
import { CreditPreviewer } from '../credit-previewer/credit-previewer.component';
import { CreditContext } from 'src/credits/credit-context';
import { useCreditBuilder } from 'src/credits/credit-builder.hook';
import { getCredit } from 'src/resources/credits.resources';
import { CreditHistory } from './credit-history.component';
import { PdfTronFileError } from 'src/common/components/pdf-tron-file-error.component';
import { CreditBuilder } from '../credit-builder/credit-builder.component';
import { IntegrationsContext } from 'src/common/integrations/integrations-context';
import { getQboFromIntegrationsObj } from 'src/common/integrations/qbo.helpers';
import { getIntegrations } from 'src/resources/integrations.resources';
import { notifyAnalytics } from 'src/resources/analytics.resources';

export const CreditOverlay = ({ overlayData, show, onClose }) => {
  const [user, tenant] = useWithUserAndTenant();
  const [editing, setEditing] = useState(false);
  const { credit, creditRemaining, mode, webViewerInstance, actions } = useCreditBuilder(show, onClose, setEditing);
  const integrationContextObj = useContext(IntegrationsContext);

  const [showApplyCreditModal, setShowApplyCreditModal] = useState(false);
  const [showRefundCreditModal, setShowRefundCreditModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [fileViewerError, setFileViewerError] = useState(false);
  const [reloadCredit, setReloadCredit] = useState(0);
  const [qboIntegrationInfo, setQboIntegrationInfo] = useState(
    getQboFromIntegrationsObj(integrationContextObj?.integrations)
  );
  const [integratedCredit, setIntegratedCredit] = useState({});

  const hasIntegrationCredits =
    qboIntegrationInfo?.connected &&
    !!qboIntegrationInfo.payments?.sync_status.first_synced_at &&
    !!qboIntegrationInfo.payments?.sync_status.synced_at;

  useEffect(() => {
    if (hasIntegrationCredits && credit.id) {
      setIntegratedCredit(!!credit.third_party_id);
    } else {
      setIntegratedCredit({});
    }
  }, [hasIntegrationCredits, credit.id]);

  const handleApplyCredit = (creditAmount, selectedInvoice) => {
    actions.applyCredit(creditAmount, selectedInvoice).then(() => {
      setShowApplyCreditModal(false);
      setReloadTrigger(true);
    });
  };

  const handleRefundCredit = (refundDate, refundMethod, refundAmount) => {
    actions.refund(refundDate, refundMethod, refundAmount).then(() => {
      setShowRefundCreditModal(false);
      setReloadTrigger(true);
      sendQboAnalyticsEvent({
        credit_id: credit.id,
        refund_amount: refundAmount,
        refund_method: refundMethod,
        refund_date: refundDate,
      });
    });
  };

  useEffect(() => {
    if ((!featureEnabled('corona_ft_qbo_id_on_tenant') || !!tenant?.qbo_credentials_id) && !integrationContextObj) {
      const subscription = getIntegrations().subscribe(integrations => {
        setQboIntegrationInfo(getQboFromIntegrationsObj(integrations));
      }, handleError);
      return () => subscription.unsubscribe();
    } else if (featureEnabled('corona_ft_qbo_id_on_tenant') && !tenant?.qbo_credentials_id) {
      setQboIntegrationInfo();
    }
  }, [integrationContextObj, tenant?.qbo_credentials_id]);

  useEffect(() => {
    if (!show || !overlayData.creditId) return;
    const subscription = getCredit(overlayData.creditId).subscribe(
      credit => actions.loadCredit(credit, overlayData.amount_remaining || credit.amount_remaining),
      handleError
    );

    return () => subscription.unsubscribe();
  }, [show, overlayData.creditId, reloadCredit]);

  useEffect(() => {
    if (!reloadTrigger) return;
    const subscription = getCredit(overlayData.creditId).subscribe(credit => {
      actions.loadCredit(credit);
      setReloadTrigger(false);
    }, handleError);

    return () => subscription.unsubscribe();
  }, [reloadTrigger]);

  const closeCreditBuilder = hasSaved => {
    if (hasSaved) {
      setReloadCredit(reloadCredit + 1);
    }
    setEditing(false);
  };

  const sendQboAnalyticsEvent = data => {
    if (integratedCredit?.third_party_payment_id) {
      notifyAnalytics(user, 'qbo', 'qbo_payments', 'qbo_credit_refunded', data);
    }
  };

  return (
    <CreditContext.Provider
      value={{
        credit,
        creditRemaining,
        mode,
        webViewerInstance,
        actions,
      }}>
      {!fileViewerError ? (
        <CpOverlay
          show={show}
          onClose={() => {
            setEditing(false);
            onClose();
          }}
          small>
          {editing ? (
            <CreditBuilder
              clientId={credit?.relationships?.for?.id}
              creditId={credit.id}
              thirdPartyId={credit.third_party_id}
              onClose={closeCreditBuilder}
              qboIntegrationInfo={qboIntegrationInfo}
            />
          ) : (
            <>
              <CpOverlay.Header
                title={!credit.id ? 'Credit' : `Credit ${credit.credit_number} - ${credit.status.capitalize()}`}>
                {credit.id && (
                  <CreditOverlayActions
                    onApplyCredit={() => setShowApplyCreditModal(true)}
                    onRefundCredit={() => setShowRefundCreditModal(true)}
                    onDeleteCredit={() =>
                      credit.invoice_payments ? setShowDeleteConfirmModal(true) : actions.delete()
                    }
                  />
                )}
              </CpOverlay.Header>
              <CpOverlay.Body>
                <CreditPreviewer setFileViewerError={setFileViewerError} />
                <CreditHistory credit={credit} onClose={onClose} />
              </CpOverlay.Body>
            </>
          )}
        </CpOverlay>
      ) : (
        <PdfTronFileError showError={fileViewerError} />
      )}
      <ApplyCreditModal
        show={showApplyCreditModal}
        onClose={() => setShowApplyCreditModal(false)}
        onApplyCredit={handleApplyCredit}
        qboIntegrationInfo={qboIntegrationInfo}
      />
      <RefundCreditModal
        show={showRefundCreditModal}
        onClose={() => setShowRefundCreditModal(false)}
        onRefundCredit={handleRefundCredit}
        integratedCredit={integratedCredit}
      />
      <CpModal show={showDeleteConfirmModal} onClose={() => setShowDeleteConfirmModal(false)}>
        <CpModal.Header title="Delete credit" />
        <CpModal.Body>
          The selected credit has been applied to an invoice. Deleting will remove the credit from the invoice.
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton btnType="primary" onClick={() => actions.delete()} className="cp-mr-8">
            Delete
          </CpButton>
          <CpButton btnType="flat" onClick={() => setShowDeleteConfirmModal(false)}>
            Cancel
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    </CreditContext.Provider>
  );
};
