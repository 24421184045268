import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import { featureEnabled } from 'feature-toggles!sofe';
import { getClients, getClientsAndClientGroups } from 'src/resources/clients.resources';
import { handleError } from 'src/common/handle-error.helper';

export const ClientName = ({ value, filterEntryItems, update, disabled, ft_crm, invoiceType }) => {
  const [searchTrigger, setSearchTrigger] = useState(ft_crm && featureEnabled('ft_gs_group_billing_v1'));
  const [search, setSearch] = useState('');
  const [clients, setClients] = useState([]);
  const includeClientGroups = ft_crm && featureEnabled('ft_gs_group_billing_v1') && invoiceType === 'oneTime';

  useEffect(() => {
    if (!searchTrigger) return;
    if (search.length === 0 && !includeClientGroups) {
      setSearchTrigger(false);
      return;
    }
    let clientSubscription;

    if (includeClientGroups) {
      clientSubscription = getClientsAndClientGroups(search).subscribe(clients => {
        setClients([
          ...(clients.client_groups.length > 0
            ? [
                {
                  id: 1,
                  name: 'Client Groups',
                  icon: 'person',
                  showCount: false,
                  data: clients.client_groups.map(group => ({ ...group, type: 'group' })),
                },
              ]
            : []),
          ...(clients.clients.length > 0
            ? [
                {
                  id: 2,
                  name: 'Clients',
                  icon: 'person',
                  showCount: false,
                  data: clients.clients.map(client => ({ ...client, type: 'client' })),
                },
              ]
            : []),
        ]);
        setSearchTrigger(false);
      }, handleError);
    } else {
      clientSubscription = getClients(search).subscribe(clients => {
        setClients(clients);
        setSearchTrigger(false);
      }, handleError);
    }

    return () => clientSubscription.unsubscribe();
  }, [searchTrigger]);

  const searchClients = () => setSearchTrigger(true);

  const debouncedSearch = useCallback(debounce(searchClients, 1000), []);

  return (
    <div>
      <label>
        Client Name
        {includeClientGroups && ' or Client Group'}
        <span className="cps-color-primary">*</span>
      </label>
      <div style={{ width: '240px' }}>
        <CpSelectSingle
          triggerIsBlock
          contentWidth={ft_crm && featureEnabled('ft_gs_group_billing_v1') ? 'block' : 'md'}
          fixedContent
          isGroupData={includeClientGroups}
          disabled={disabled}
          data={clients}
          value={value}
          placeholder="Search for client"
          onChange={({ id, name, type, is_business }) => {
            if (value?.id === id) return;
            if (type === 'group') {
              update('clientGroup', { id, name, is_active: true });
              update('client', undefined);
            } else {
              update('client', { id, name });
              update('clientGroup', undefined);
              update('isBusinessClient', is_business);
            }
            if (ft_crm && featureEnabled('ft_gs_group_billing_v1')) {
              filterEntryItems?.(id, type);
            }
          }}
          searchOnChange={value => {
            setSearch(value);
            debouncedSearch();
          }}
          searchValue={search}
        />
      </div>
    </div>
  );
};
